import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/snippet-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p className="t-lead">LearnUXD brings you this course on how to build websites from the ground up. Master basic skills with HTML, CSS, and the core principles of designing effective websites.</p>
    <p>{`By the end of this course students will be able to:`}</p>
    <ul>
      <li parentName="ul">{`Recall terms, settings, and key concepts related to HTML, CSS and working with the World Wide Web and communicate professionally using knowledge of web design terms.`}</li>
      <li parentName="ul">{`Organize and structure web content for various users.`}</li>
      <li parentName="ul">{`Write, edit, and modify HTML and CSS to develop and enhance web pages using relevant software.`}</li>
      <li parentName="ul">{`Locate and use various resources to further develop knowledge and skills for web design.`}</li>
      <li parentName="ul">{`Recreate basic layouts and full websites with HTML and CSS.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      